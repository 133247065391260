import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Button,
  HStack,
  Stack,
  Text,
  Tooltip,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { Preview } from 'admin/components/authHub/preview';
import { settings } from 'admin/components/authHub/settings';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TargetType, FormType } from 'admin/types/authHub';
import { UseFormWatch } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useAuthHubContext } from 'admin/components/authHub/authHubContext';
import { ThemeProps } from '../preview/Theme';
import { Controls } from './Controls';
import { PageSelector } from './PageSelector';

const themeWatch = (watch: UseFormWatch<FormType>): ThemeProps => ({
  backgroundColor: watch('theme.backgroundColor') as string,
  contentBackgroundColor: watch('theme.contentBackgroundColor') as string,
  contentBorderColor: watch('theme.contentBorderColor') as string,
  focusBaseColor: watch('theme.focusBaseColor') as string,
  hoverBaseColor: watch('theme.hoverBaseColor') as string,
  titleTextColor: watch('theme.titleTextColor') as string,
  baseTextColor: watch('theme.baseTextColor') as string,
  inputTextColor: watch('theme.inputTextColor') as string,
  inputTextBorderColor: watch('theme.inputTextBorderColor') as string,
  inputTextBackgroundColor: watch('theme.inputTextBackgroundColor') as string,
  contentSeparatorColor: watch('theme.contentSeparatorColor') as string,
  linkTextColor: watch('theme.linkTextColor') as string,
  primaryButtonColor: watch('theme.primaryButtonColor') as string,
  primaryButtonTextColor: watch('theme.primaryButtonTextColor') as string,
  secondaryButtonColor: watch('theme.secondaryButtonColor') as string,
  secondaryButtonTextColor: watch('theme.secondaryButtonTextColor') as string,
  secondaryButtonBorderColor: watch(
    'theme.secondaryButtonBorderColor',
  ) as string,
  selectedColor: watch('theme.selectedColor') as string,
  requiredMarkColor: watch('theme.requiredMarkColor') as string,
  successMessageColor: watch('theme.successMessageColor') as string,
  errorMessageColor: watch('theme.errorMessageColor') as string,
});

const footerWatch = (watch: UseFormWatch<FormType>) => {
  const termsList = [1, 2, 3, 4, 5, 6, 7]
    .map((i) => ({
      key: `terms${i}`,
      name: watch(`footer.termsName${i}`) as string,
      link: watch(`footer.termsLink${i}`) as string,
    }))
    .filter(({ name, link }) => name && link);

  return {
    termsList,
    copyrightText: watch('footer.copyrightText') as string,
  };
};

type ForceType = {
  hover: boolean;
  focus: boolean;
};

const ForceControl = ({
  force,
  setForce,
}: {
  force: ForceType;
  setForce: (_: ForceType) => void;
}) => (
  <Flex justify="end" bgColor="#dedede" w="full" p={2}>
    <CheckboxGroup>
      <Stack spacing={[1, 5]} direction={['column', 'row']}>
        <Checkbox onChange={() => setForce({ ...force, focus: !force.focus })}>
          フォーカスを表示
        </Checkbox>
        <Checkbox onChange={() => setForce({ ...force, hover: !force.hover })}>
          ホバーを表示
        </Checkbox>
      </Stack>
    </CheckboxGroup>
  </Flex>
);

const getDefaultIndex = (basename: string) => {
  if (basename === 'theme') return 0;
  if (basename === 'embeddedTags') return 2;

  return 1;
};

const AuthHubEditorInner = ({ target }: { target: TargetType }) => {
  const { form, userPoolId, authHubId } = useAuthHubContext();
  const [force, setForce] = useState<ForceType>({ hover: false, focus: false });
  const navigate = useNavigate();
  const location = useLocation();
  const [basename] = location.pathname.split('/').slice(-1);
  const defaultIndex = getDefaultIndex(basename);
  const settingType = userPoolId ? 'organization' : 'tenant';
  const prefix =
    settingType === 'organization' && userPoolId
      ? `/admin/authHub/${userPoolId}/`
      : '/authHub/';

  const {
    register,
    getValues,
    formState: { errors },
    watch,
  } = form;

  const footer = footerWatch(watch);

  const theme = themeWatch(watch);

  if (!authHubId) {
    throw new Error('authHubIdを取得できません');
  }

  return (
    <VStack w="full" mt={8}>
      <Box w="full" display="flex" justifyContent="space-between">
        <Box>
          <Text fontSize="xl" as="b">
            Auth Hub詳細設定
          </Text>
        </Box>
        <HStack spacing={4}>
          <Tooltip label="Auth Hub詳細設定画面で設定した内容を一括で保存します。">
            <QuestionIcon />
          </Tooltip>
          <Button colorScheme="blue" type="submit" mt={4}>
            設定を保存
          </Button>
        </HStack>
      </Box>
      <Flex
        alignSelf="flex-start"
        w="full"
        bgColor="white"
        p="16px"
        rounded="8px"
      >
        <Tabs w="full" defaultIndex={defaultIndex}>
          <TabList>
            <Tab onClick={() => navigate(`${prefix}${authHubId}/theme`)}>
              <Text>カラー設定</Text>
            </Tab>
            <Tab onClick={() => navigate(`${prefix}${authHubId}/header`)}>
              <Text>画面パーツ設定</Text>
            </Tab>
            <Tab onClick={() => navigate(`${prefix}${authHubId}/embeddedTags`)}>
              <Text>各ページに埋め込むタグの設定</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <Stack mt={4}>
              {target !== 'theme' && target !== 'embeddedTags' && (
                <PageSelector
                  prefix={prefix}
                  authHubId={authHubId}
                  settingType={settingType}
                  target={target}
                />
              )}
              <HStack mt={2} w="full">
                <Stack
                  alignSelf="flex-start"
                  mr="1rem"
                  minWidth="320px"
                  overflowY="scroll"
                  h="calc(100vh - 274px)"
                  p="16px"
                  rounded="8px"
                  bgColor="#F5F7F9"
                >
                  <Controls
                    register={register}
                    settings={settings}
                    settingType={settingType}
                    target={target}
                    errors={errors}
                    getValues={getValues}
                  />
                </Stack>
                {target !== 'embeddedTags' && (
                  <Stack
                    backgroundColor={theme.backgroundColor}
                    alignSelf="flex-start"
                    w="full"
                  >
                    <ForceControl force={force} setForce={setForce} />
                    <Preview
                      pageName={target}
                      watch={watch}
                      theme={theme}
                      footer={footer}
                      force={force}
                      settingType={settingType}
                    />
                  </Stack>
                )}
              </HStack>
            </Stack>
          </TabPanels>
        </Tabs>
      </Flex>
    </VStack>
  );
};

export const AuthHubEditor = withSuspenseAndErrorBoundary(AuthHubEditorInner, {
  ErrorComponent: null,
  LoadingComponent: null,
});
