const groupName = 'header';

export default [
  {
    name: `${groupName}.logo`,
    label: 'ロゴ',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {},
    elementOptions: {},
  },
] as const;
